import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, LinkIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { DropdownMenuItem } from "src/components/DropdownMenu/types";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { IExpandedNode, NODES_TO_HIDE_FROM_DAG, NodeType } from "src/features/models/discover/INode";

export const NodeSidepaneMenu = ({ node }: { node: IExpandedNode }) => {

    const items: DropdownMenuItem[] = [
        {
            name: 'Copy link',
            icon: <LinkIcon width="16" height="16" />,
            onClick: () => navigator.clipboard.writeText(`${window.location.origin}${getNodeUrl(node.id, 'table')}`)
        }
    ];
    if (node.type === NodeType.TableauWorkbook) {
        items.push({
            name: 'Isolate item',
            icon: <ViewfinderCircleIcon width="16" height="16" />,
            onClick: () => window.open(`/data-model?Tableau+workbook=${node.name}&view=graph`, '_blank')
        });
    }
    if (!NODES_TO_HIDE_FROM_DAG.includes(node.type)) {
        items.push(
            {
                name: 'Isolate item',
                icon: <ViewfinderCircleIcon width="16" height="16" />,
                onClick: () => window.open(getNodeUrl(node.id), '_blank')
            },
            {
                name: 'Show downstream dependents',
                icon: <ChevronDoubleRightIcon width="16" height="16" />,
                onClick: () => window.open(`/data-model?view=table&Downstream dependents=${node.id}`, '_blank')

            },
            {
                name: 'Show upstream dependencies',
                icon: <ChevronDoubleLeftIcon width="16" height="16" />,
                onClick: () => window.open(`/data-model?view=table&Upstream dependencies=${node.id}`, '_blank')
            }
        );
    }
    return (
        <DropdownMenu items={items} className="-ml-48 w-52 bg-white" />
    );
};

import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode, ISubnode, nodeNameMap, NodeType, subnodeNameMap, SubnodeType } from "../../../INode";
import { SubNodesListProps } from "src/features/models/discover/sidepane/NodeSidepane/types";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { NodeSidepaneLoader } from "src/features/models/discover/sidepane/NodeSidepane/NodeSidepaneLoader";
import { BackendNodeType } from "src/services/nodes/types";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { notify } from "src/components/Toaster";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";
import { mapBackendNodeTypeToLocalNodeType } from "src/services/nodes/transformers";

type TableauDataSourceSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauDataSourceSidepane = ({ node, setCanCloseModal }: TableauDataSourceSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        SubNodesListComponent={SubNodesListComponent}
    />;
};

enum Tab {
    Tables = 'Tables',
    Dimensions = 'Dimensions',
    Measures = 'Measures',
    CalculatedFields = 'Calculated Fields',
}

type ISubnodeWithGroupBy = ISubnode & { groupBy?: string };

export const SubNodesListComponent = ({ subnodes, onClick, parentNode, setCanCloseModal }: SubNodesListProps) => {
    const [activeTab, setActiveTab] = useState<Tab>(Tab.Tables);
    const subnodesFilteredByTab: ISubnodeWithGroupBy[] = useMemo(() => filterSubnodesByTab(subnodes, activeTab), [subnodes, activeTab]);
    return (
        <div className="mt-4">
            <div className="flex gap-0.5 overflow-auto rounded bg-slate-200 p-0.5 text-sm font-light text-secondary">
                {[Tab.Tables, Tab.Dimensions, Tab.Measures, Tab.CalculatedFields].map((tab) => {
                    const isActive = tab === activeTab;
                    const background = isActive ? 'bg-white' : 'bg-slate-200';
                    return (
                        <div
                            key={tab}
                            className={`w-full cursor-pointer whitespace-nowrap rounded px-1 py-1 text-center ${background} hover:bg-white`}
                            onClick={() => setActiveTab(tab)}>
                            {tab}
                        </div>
                    );
                })}
            </div>
            <div className="mt-2">
                {
                    activeTab !== Tab.Tables && subnodesFilteredByTab.map((subnode, index) => (
                        <>
                            {subnode.groupBy && subnodesFilteredByTab[index - 1]?.groupBy !== subnode.groupBy && <div className="my-2 text-tertiary">{subnode.groupBy}</div>}
                            <SubnodeItem key={index} subnode={subnode} onClick={onClick} parentNode={parentNode} />
                        </>
                    ))
                }
                {
                    activeTab === Tab.Tables && <TableList setCanCloseModal={setCanCloseModal} node={parentNode} />
                }
            </div>
        </div>
    );
};

const filterSubnodesByTab = (subnodes: ISubnode[], activeType: Tab): ISubnodeWithGroupBy[] => {
    switch (activeType) {
        case Tab.Tables:
            return [];
        case Tab.Dimensions:
            return subnodes.filter((subnode) => subnode.type === SubnodeType.Dimension && !subnode.isCalculated);
        case Tab.Measures:
            return subnodes.filter((subnode) => subnode.type === SubnodeType.Measure && !subnode.isCalculated);
        case Tab.CalculatedFields:
            return subnodes
                .filter((sn) => sn.isCalculated && [SubnodeType.Dimension, SubnodeType.Measure].includes(sn.type))
                .map(sn => ({ ...sn, groupBy: sn.type === SubnodeType.Dimension ? 'Dimensions' : 'Measures' }))
                .sort((a, b) => a.groupBy === b.groupBy ? a.name.localeCompare(b.name) : a.groupBy.localeCompare(b.groupBy));
    }
};

const SubnodeItem = ({ subnode, onClick, parentNode }: { subnode: ISubnode, parentNode: IExpandedNode, onClick?: (subnode: ISubnode) => void }) => {
    return (
        <div
            className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
            onClick={() => onClick && onClick(subnode)}>
            <div className="flex items-center gap-1 overflow-hidden">
                <NodeIcon type={parentNode.type} subnodeType={subnode.type} />
                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                    {subnode.name}
                </span>
                {subnode.hasProposals && <SparklesIcon width="14" height="14" className="text-surface-primary" />}
            </div>
            <span className="text-xs text-tertiary">{subnodeNameMap.get(subnode.type)}</span>
        </div>
    );
};

const TableList = ({ node, setCanCloseModal }: { node: IExpandedNode, setCanCloseModal: (canClose: boolean) => void }) => {
    const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);
    const accountId = useSelector(selectActiveAccountId);
    const tableResourceTypes = [BackendNodeType.Table, BackendNodeType.TableauCustomQuery, BackendNodeType.DBTModel];
    const eql = `type IN (${[...tableResourceTypes, BackendNodeType.TableauPublishedDataSource].map(t => `'${t}'`).join(',')}) AND HAS downstream(uri='${node.id}',1) OR (HAS DOWNSTREAM(type='${BackendNodeType.TableauPublishedDataSource}', 1) AND HAS DOWNSTREAM(uri='${node.id}', 2))`;
    const getUpstreamResourcesQuery = useGetDataModelResourcesQuery({ eql, page: 1, pageSize: 100, accountId });
    const resources = getUpstreamResourcesQuery.data?.items || [];
    const publishedDataSource = resources.find(r => r.type === NodeType.TableauPublishedDataSource);
    const tables = resources.filter(r => tableResourceTypes.map(t => mapBackendNodeTypeToLocalNodeType.get(t)).includes(r.type));
    useEffect(() => {
        setCanCloseModal(!selectedResourceId);
    }, [selectedResourceId, setCanCloseModal]);
    const goToPublishedDataSource = () => {
        if (!publishedDataSource?.id) {
            notify('Published data source not found', 'error');
        }
        else {
            setSelectedResourceId(publishedDataSource.id);
        }
    };
    return (
        <>
        {
            getUpstreamResourcesQuery.isFetching && <ComponentLoader/>
        }
            {
                tables.map(table => (
                    <div
                        className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
                        onClick={() => setSelectedResourceId(table.id)}
                        key={table.id}>
                        <div className="flex items-center gap-1 overflow-hidden">
                            <NodeIcon type={table.type} />
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                                {table.name}
                            </span>
                            {table.hasProposals && <SparklesIcon width="14" height="14" className="text-surface-primary" />}
                        </div>
                        <span className="text-xs text-tertiary">{nodeNameMap.get(table.type)}</span>
                    </div>
                ))
            }
            {
                node.type === NodeType.TableauEmbeddedDataSource && publishedDataSource && tables.length > 0 && (
                    <div className="mt-2 text-xs text-tertiary hover:text-slate-600 cursor-pointer" onClick={goToPublishedDataSource}>
                        {`* Some of the above tables are inherited from ${publishedDataSource.name}.`}
                    </div>
                )
            }
            <NodeSidepaneLoader nodeId={selectedResourceId || ''} isOpen={!!selectedResourceId} onClose={() => setSelectedResourceId(null)} />
        </>
    );
};

import { NodeType, Swimlane } from "../../INode";
import { ExpandedNode, IDAGNode } from "./types";

export const generateOrphanNodes = (nodes: IDAGNode[], expandedNodes: ExpandedNode[], withFilters: boolean): IDAGNode[] => {
    const parentIdsOfViewsAndModels = [...new Set(nodes.filter(n => [NodeType.DataModel, NodeType.LookerView].includes(n.type)).flatMap(n => n.parents))];
    const parentsWithoutNodes = parentIdsOfViewsAndModels.filter(parent => !nodes.some(node => node.id === parent));
    if (withFilters) {
        removeNonExpandedOrphans(parentsWithoutNodes, expandedNodes, nodes);
    }
    const generatedOrphanNodes = parentsWithoutNodes.map(parent => {
        const parentParsed = parent.split('.');
        const name = parentParsed.pop() || '';
        const databaseSchema = parentParsed.pop() || '';
        const database = parentParsed.pop() || '';
        return {
            id: parent,
            name,
            swimlane: Swimlane.transformations_and_metrics,
            parents: [],
            type: NodeType.GenericDataTransformation,
            subnodes: [],
            database,
            databaseSchema,
            isConnectedNode: withFilters,
            owner: null,
            firstSeen: 0,
            dbtProject: null,
            lookerFolder: null,
            lookerModel: null,
            sourceDirectory: null,
            lookerHost: null,
            totalViews7Days: null,
            totalViews30Days: null,
            isTrivialSql: null,
            totalQueries14Days: null,
            databaseTechnology: null,
            totalQueries30Days: null,
            totalQueries60Days: null,
            distinctUsers14Days: null,
            distinctUsers30Days: null,
            distinctUsers60Days: null,
            distinctUserImpressions14Days: null,
            distinctUserImpressions30Days: null,
            distinctUserImpressions60Days: null,
            totalImpressions14Days: null,
            totalImpressions30Days: null,
            totalImpressions60Days: null,
            nativeLastDataUpdate: null,
            isCalculated: null, 
            refreshFrequency: null
        };
    });
    return generatedOrphanNodes;
};

const removeNonExpandedOrphans = (parentsWithoutNodes: string[], expandedNodes: ExpandedNode[], nodes: IDAGNode[]) => {
    for (let i = 0; i < parentsWithoutNodes.length; i++) {
        const parent = parentsWithoutNodes[i];
        const downstreamNodeIds = getDownstreamNodes(parent, nodes).map(n => n.id);
        const hasFullyExpandedChildren = downstreamNodeIds.some(childNodeId => expandedNodes.some(expandedNode => expandedNode.nodeId === childNodeId && expandedNode.direction === 'upstream' && expandedNode.depth === null));
        const hasDirectlyExpandedChild = expandedNodes.some(expandedNode => nodes.find(n => n.id === expandedNode.nodeId)?.parents.includes(parent) && expandedNode.direction === 'upstream' && expandedNode.depth === 1);
        if (!hasFullyExpandedChildren && !hasDirectlyExpandedChild) {
            parentsWithoutNodes.splice(i, 1);
            i--;
        }
    }
};

const getDownstreamNodes = (nodeId: string, nodes: IDAGNode[], visited = new Set<string>()): IDAGNode[] => {
    if (visited.has(nodeId)) {
        return [];
    }
    visited.add(nodeId);
    const downstreamNodes = nodes.filter(n => n.parents.includes(nodeId));

    return [
        ...downstreamNodes,
        ...downstreamNodes.flatMap(n => getDownstreamNodes(n.id, nodes, visited))
    ];
};

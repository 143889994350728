import { ChartBarIcon, EyeIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { events, trackEvent } from "src/infrastructure/analytics";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { NodeUsageAppType } from "src/services/nodes/types";
import { IExpandedNode, ISourceNodeUsage, IUserNodeUsage, ISubnode, NodeType, SubnodeType, INodeUsage } from "src/features/models/discover/INode";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { LookerColorlessIcon } from "src/assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";

export const NodeUsage = ({ node }: { node: IExpandedNode | ISubnode }) => {
    const { showUsageInSidepane } = useFlags();
    const showViews = !!node.last7DaysViews || !!node.last30DaysViews;
    const showUsage = !!node.usage && showUsageInSidepane;
    const showImpressions = !!node.impressions;
    if (!showViews && !showUsage && !showImpressions) return null;
    return (
        <div className="mt-4 p-2 rounded bg-surface-light flex flex-col gap-2 text-sm border border-border">
            {
                showViews && <Views last7DaysViews={node.last7DaysViews} last30DaysViews={node.last30DaysViews} />
            }
            {
                showViews && showUsage && <div className="w-full h-[1px] bg-border"></div>
            }
            {
                showUsage && <Usage label='uses' usage={node.usage} nodeType={node.type} />
            }
            {
                showUsage && showImpressions && <div className="w-full h-[1px] bg-border"></div>
            }
            {
                showImpressions && <Usage label='impressions' usage={node.impressions} nodeType={node.type} />
            }
        </div>
    );
};

const Views = ({ last7DaysViews, last30DaysViews }: { last7DaysViews: number | null, last30DaysViews: number | null }) => {
    return (
        <div className="flex gap-4">
            <div className="flex gap-1 items-center">
                <EyeIcon width="14" height="14" className="text-text-primary mr-1" />
                <div className="text-text-primary text-base">{last7DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">7D</div>
            </div>
            <div className="flex gap-1 items-center">
                <div className="text-text-primary text-base">{last30DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">30D</div>
            </div>
        </div>
    );
};

const Usage = ({ nodeType, label, usage }: { nodeType: NodeType | SubnodeType, label: string, usage: INodeUsage | null }) => {
    const [showExpanded, setShowExpanded] = useState<boolean>(false);
    const [timeFrame, setTimeFrame] = useState<'14D' | '30D' | '60D'>('14D');
    let totalCount = 0;
    let sources: ISourceNodeUsage[] = [];
    let users: IUserNodeUsage[] = [];
    switch (timeFrame) {
        case '14D':
            totalCount = usage?.usage14Days || 0;
            sources = usage?.sources14Days || [];
            users = usage?.users14Days || [];
            break;
        case '30D':
            totalCount = usage?.usage30Days || 0;
            sources = usage?.sources30Days || [];
            users = usage?.users30Days || [];
            break;
        case '60D':
            totalCount = usage?.usage60Days || 0;
            sources = usage?.sources60Days || [];
            users = usage?.users60Days || [];
            break;
    }
    const toggleExpand = () => {
        if (!totalCount) return;
        if (!showExpanded) {
            trackEvent(events.nodeUsageExpanded14Days, { node_type: nodeType });
        }
        setShowExpanded(!showExpanded);
    };
    if (!usage) return null;
    return (
        <div>
            <div>
                <div className="flex justify-between">
                    <div className="flex gap-1 items-center">
                        <ChartBarIcon width="14" height="14" className="text-text-primary mr-1" />
                        <div className="text-text-primary text-base">{totalCount} {label}</div>
                        <div className="w-fit rounded p-0.5 bg-white flex">
                            {
                                ['14D', '30D', '60D'].map(tf => (
                                    <div
                                        key={tf}
                                        className={`rounded cursor-pointer py-1 px-2 ${timeFrame === tf ? 'bg-slate-100 text-surface-primary' : ''}`}
                                        onClick={() => setTimeFrame(tf as '14D' | '30D' | '60D')}
                                    >
                                        {tf}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        totalCount ? (
                            <div onClick={toggleExpand} className="cursor-pointer my-auto h-fit w-fit text-slate-400 bg-slate-100 hover:text-slate-500 p-0.5 border rounded border-border">
                                {
                                    showExpanded ? <ChevronDownIcon width="14" height="14" /> : <ChevronUpIcon width="14" height="14" />
                                }
                            </div>
                        ) : null
                    }
                </div>
                <ExpandedUsage show={showExpanded} sources={sources} users={users} />
            </div>
        </div>
    );
};

const ExpandedUsage = ({ show, sources, users }: { show: boolean, sources: ISourceNodeUsage[] | undefined, users: IUserNodeUsage[] | undefined }) => {
    const accountId = useSelector(selectActiveAccountId);
    const sourcesToShow = useMemo(() => (sources || []).slice(0, 5), [sources]);
    const sourceResources = useGetDataModelResourcesQuery({
        accountId,
        page: 1,
        pageSize: 100,
        eql: `uri in (${sourcesToShow.map(s => s.utl).filter(u => !!u).map(utl => `'${utl}'`).join(',')})`
    });
    const sourcesWithTitles = useMemo(() => sourcesToShow.map(s => {
        const utl = s?.utl || '';
        const title = sourceResources.data?.items.find(r => r.id === utl)?.name || '';
        return { ...s, title };
    }), [sourcesToShow, sourceResources.data]);

    const withUsers = (users || []).length > 0;
    const withSources = (sources || []).length > 0;
    if (!show) return null;
    return (
        <div className="flex flex-col justify-between mt-2 gap-2 text-xs">
            {
                withSources && (
                    <div className="flex-1">
                        <div className="text-tertiary text-sm">TOP 5 QUERIED SOURCES</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                sourcesWithTitles.map(source => (
                                    <Source key={`${JSON.stringify(source)}`} source={source} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                withUsers && (
                    <div className="flex-1 border-t border-border pt-2">
                        <div className="text-tertiary text-sm">TOP USERS</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                (users || []).slice(0, 5).map(user => (
                                    <User key={user.name} user={user} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const Source = ({ source }: { source: ISourceNodeUsage & { title?: string } }) => {
    const goToSource = () => {
        if (source.utl) {
            window.location.href = getNodeUrl(source.utl);
        }
    };
    let title = source.title || 'Other';
    let subtitle = sourceTypeMap[source.type] || '';

    if (source.type == NodeUsageAppType.other) {
        subtitle = '';
    } else if (source.type === NodeUsageAppType.explore) {
        title = 'Explore';
        subtitle = '';
    } else if (source.dashboardTitle) {
        subtitle = `${subtitle}: ${source.dashboardTitle}`;
    }
    return (
        <div className="flex gap-1 items-center">
            <div className="bg-surface-light border rounded border-border h-fit">
                <LookerColorlessIcon fill="#94a3b8" width="12" height="12" />
            </div>
            <div className={`text-sm ${source.utl ? 'cursor-pointer text-text-primary' : 'text-slate-400 italic'}`} onClick={goToSource}><TextWithEllipsisAndTooltip text={title || ''} maxChars={30} /></div>
            <div className="text-tertiary"><TextWithEllipsisAndTooltip text={subtitle} maxChars={25} /></div>
            <div className="text-tertiary ml-auto">{source.number}</div>
        </div>
    );
};

const sourceTypeMap = {
    [NodeUsageAppType.look]: 'Look',
    [NodeUsageAppType.dashboard_element]: 'Tile',
    [NodeUsageAppType.api_query]: 'API',
    [NodeUsageAppType.explore]: 'Explore',
    [NodeUsageAppType.other]: 'Other'
};

const User = ({ user }: { user: { name: string, number: number, email: string } }) => {
    return (
        <div className="flex gap-1 items-center">
            <div className="text-sm text-text-primary"><TextWithEllipsisAndTooltip text={user.name || ''} maxChars={35} /></div>
            <div className="text-tertiary">{user.email}</div>
            <div className="text-tertiary ml-auto">{user.number}</div>
        </div>
    );
};

import { LinkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { getNodeUrl } from "../getNodeUrl";

export const CopyNodeUrlButton = ({ utl }: { utl: string }) => {
    const [copied, setCopied] = useState(false);
    const text = copied ? 'Copied' : 'Copy link';
    const copy = () => {
        if (!copied) {
            navigator.clipboard.writeText(`${window.location.origin}${getNodeUrl(utl, 'table')}`);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };
    return (
        <div>
            <LinkIcon id="copy-url-icon" onClick={copy} width={12} height={12} className='focus:outline-none cursor-pointer color-slate-300 hover:color-text-tertiary' />
            <Tooltip anchorSelect="#copy-url-icon" className="!p-1 !text-sm">{text}</Tooltip>
        </div>
    );
};
import { MagnifyingGlassIcon, CheckIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import Button from "../../button/Button";
import { ButtonTypes } from "../../button/types";
import { TextWithEllipsisAndTooltip } from "../../TextWithEllipsisAndTooltip";
import Input from "../../form/Input";
import { FilterComponentProps, FilterValue, SelectFilterOption } from "./types";
import { useKeyPressEvent } from "react-use";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";

type SelectFilterProps = FilterComponentProps & {
    label: string;
    options: SelectFilterOption[];
    isMulti: boolean;
    search?: 'inMemory' | 'custom';
    onQueryChange?: (query: string) => void;
    isLoading?: boolean;
}

export const SelectFilter = ({ value, onSave, onClose, label, options, isMulti, search, onQueryChange, isLoading = false }: SelectFilterProps) => {
    const [selected, setSelected] = useState<FilterValue[]>(value?.split(',') || []);
    const [query, setQuery] = useState<string>('');

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (onQueryChange) {
            onQueryChange(e.target.value);
        }
    };
    const onSelection = (selection: FilterValue) => {
        const isSelected = selected.includes(selection);
        if (isSelected) {
            setSelected(selected.filter(s => s !== selection));
        }
        else if (isMulti) {
            setSelected([...selected, selection]);
        } else {
            setSelected([selection]);
        }
    };
    const apply = useCallback(() => {
        onSave(selected.length > 0 ? selected.join(',') : null);
        onClose();
    }, [onClose, onSave, selected]);

    const clear = () => {
        setSelected([]);
    };

    const selectAll = () => {
        setSelected(options.map(option => option.value));
    };

    useKeyPressEvent('Enter', () => {
        apply();
    });

    const filteredOptions = useMemo(() => {
        if (search == 'inMemory') {
            return options.filter(option => !query || option.label.toLowerCase().includes(query.toLowerCase()));
        }
        else {
            return options;
        }
    }, [options, query, search]);

    if (!options.length && search != 'custom') return <EmptyState label={label} onClose={onClose} />;

    return (
        <div className="w-64 p-2">
            {
                search ? (
                    <Input
                        placeholder={label}
                        value={query}
                        onInputChange={onInputChange}
                        icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                        className="w-full"
                        focus={true}
                    />
                ) : <div className="text-text-primary mb-2">{label}</div>
            }
            {
                isMulti && (
                    <div className="flex gap-1 mt-2 text-surface-primary text-sm">
                        <span onClick={selectAll} className="cursor-pointer">Select all</span>
                        -
                        <span onClick={clear} className="cursor-pointer">Clear</span>
                    </div>
                )
            }
            <div className="p-2 flex flex-col max-h-[40vh] overflow-auto">
                {
                    !isLoading && filteredOptions.map(option => (
                        <div key={option.value} className="flex items-center justify-between bg-white hover:bg-slate-50 py-1 cursor-pointer" onClick={() => onSelection(option.value)}>
                            <div className="flex gap-2 items-center">
                                {
                                    option.icon && <div>{option.icon}</div>
                                }
                                <div className="text-text-primary"><TextWithEllipsisAndTooltip maxChars={25} text={option.label} /></div>
                            </div>
                            {selected.includes(option.value || '') && (
                                <div><CheckIcon width={16} height={16} className="text-surface-primary" /></div>
                            )}
                        </div>
                    ))
                }
                {
                    isLoading && <ComponentLoader />
                }
            </div>
            <div className="flex gap-1 mt-5">
                <Button onClick={onClose} type={ButtonTypes.secondary} text='Close' className="w-full" />
                <Button onClick={apply} type={ButtonTypes.primary} text='Apply' className="w-full" />
            </div>
        </div>
    );
};

const EmptyState = ({ label, onClose }: { label: string, onClose: () => void }) => (
    <div className="w-60 p-2">
        <div className="text-text-primary mb-2">{label}</div>
        <div className="text-text-secondary text-sm mb-2">No options available</div>
        <div className="flex gap-1 mt-5">
            <Button onClick={onClose} type={ButtonTypes.secondary} text='Close' className="w-full" />
        </div>
    </div>
);
import stakeHoldersEntityTypes from '../../features/stakeholders/stakeholdersEntityTypes';
import StakeholdersHeaderView from '../../features/stakeholders/StakeholdersQuickView';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { LogoutIcon } from '../../assets/images/icons/DelphiIcons';
import { useDescope } from '@descope/react-sdk';
import { useSelector } from 'react-redux';
import { useGetAccountsQuery } from '../../services/accounts/accounts';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetActiveAccount } from '../../features/account/AccountHooks';
import Avatar from 'react-avatar';

interface TopBarProps {
  children?: React.ReactNode;
  invite?: {
    id: number;
    type: stakeHoldersEntityTypes;
    name: string;
  };
}

const TopBar = ({ children, invite }: TopBarProps) => {
  const sdk = useDescope();
  const activeAccountId = useSelector(selectActiveAccountId);
  const { data: accounts = [] } = useGetAccountsQuery();
  const activeAccount = useGetActiveAccount(accounts, activeAccountId);

  const menuOptions = [
    {
      name: 'Log out',
      icon: <LogoutIcon className=" text-gray-400" width="18" height="18" />,
      onClick: () => {
        sdk.logout();
      }
    }
  ];

  return (
    <div className="sticky top-0 z-[2] flex h-14 items-center border-b border-slate-200 bg-white px-4">
      {/* Top Bar Content Goes Here */}
      <div className="flex w-full items-center">
        <div className="flex-1 flex items-center">
          {children}
        </div>
        <div className="w-fit weight-normal flex items-center text-muse-600">
          {invite && <StakeholdersHeaderView entityType={invite.type} entityId={invite.id} entityName={invite.name} />}
          <DropdownMenu items={menuOptions} className="-ml-24 mt-[38px] w-44 bg-white">
            <Avatar
              value={(activeAccount?.name || 'Account').slice(0, 2).toLocaleUpperCase()}
              size="38"
              round={true}
              className="ml-1"
              textSizeRatio={2}
              color="rgb(156 163 175)"
            />
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

import { useNavigate } from "react-router-dom";
import { SparklesLeftArrowIcon } from "../../../assets/images/icons/DelphiIcons";
import { useDispatch } from "react-redux";
import activeProjectSlice from "../../../infrastructure/state/slices/activeProjectSlice";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../infrastructure/state/slices/activeAccountSlice";
import { useGetPatsQuery } from "../../../services/changes/changes";

export const ProjectProposals = ({ projectId }: { projectId: number }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountId = useSelector(selectActiveAccountId);
    const viewProposals = () => {
        dispatch(activeProjectSlice.actions.setActiveProject(projectId));
        navigate(`/model-changes`);
    };
    const getPats = useGetPatsQuery({ accountId, eql: `has_shift_left_potential is true AND shift_left_project='${projectId}'`, page: 1, pageSize: 1 });
    const total = getPats.data?.total || 0;

    if (!total) return null;
    return (
        <div className="text-sm mt-4 flex items-center p-2 bg-muse-50 gap-1 rounded-md">
            <div><SparklesLeftArrowIcon width="14" height="14" fill="#0047FF" /></div>
            <div className="text-primary">{total} proposals</div>
            <div className="ml-auto text-surface-primary font-medium cursor-pointer" onClick={viewProposals}>View</div>
        </div>
    );
};
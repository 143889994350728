import { useEffect, useState } from "react";
import { NodeButtons } from "../NodeSidepaneButtons";
import { NodeInfo } from "../NodeSidepane/NodeSidepaneInfo";
import { IExpandedNode, ISubnode, SubnodeType, subnodeNameMap } from "../../INode";
import { NodeIcon } from "../../NodeIcon";
import { useGetPatQuery } from "../../../../../services/changes/changes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { ExpandableText } from "../../../../../components/ExpandableText";
import { CopyNodeUrlButton } from "../CopyNodeUrlButton";
import { mapLocalSubnodeTypeToBackendSubnodeType } from "../../../../../services/nodes/transformers";
import { ShiftToDbtBanner } from "../ShiftToDbtBanner";
import { NodeUsage } from "../NodeUsage";
import { events, trackEvent } from "../../../../../infrastructure/analytics";
import { NodeButtonProps } from "../types";
import CreateChangeSidepane from "../../../../evolution/createChangeSidepane/CreateChangeSidepane";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";

type SubNodeSidepaneProps = {
    subnode: ISubnode | null;
    parentNode: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
    ButtonsComponent?: React.FC<NodeButtonProps>;
}

export const SubnodeSidepane = ({ setCanCloseModal, subnode, parentNode, ButtonsComponent = NodeButtons }: SubNodeSidepaneProps) => {
    const [showCodeModal, setShowCodeModal] = useState<boolean>(false);
    const [showProposalSidepane, setShowProposalSidepane] = useState<boolean>(false);
    const accountId = useSelector(selectActiveAccountId);
    const getPatQuery = useGetPatQuery({ accountId, uri: subnode?.id || '' }, { skip: !subnode?.hasProposals });

    useEffect(() => {
        setCanCloseModal(!showCodeModal && !showProposalSidepane);
    }, [showCodeModal, setCanCloseModal, showProposalSidepane]);

    useEffect(() => {
        if (subnode) {
            trackEvent(events.nodeSidepaneOpened, { node_type: subnode.type });
        }
    }, [subnode]);

    const {
        tags = [],
        meta = {},
        name = '',
        description = ''
    } = subnode || {};

    const onShiftToDbtClick = () => {
        setShowProposalSidepane(true);
    };

    return (
        <div className="p-2">
            {
                subnode && (
                    <div className="flex items-center gap-2">
                        <div className="bg-surface-light w-fit border border-slate-200 rounded">
                            <NodeIcon iconSize={20} type={parentNode.type} subnodeType={subnode.type} withBackground={true} withPadding={true} />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-lg text-text-primary flex gap-1 items-center"><TextWithEllipsisAndTooltip text={name} maxChars={30}/><CopyNodeUrlButton utl={generateSubnodeUtl(parentNode.id, subnode.type, subnode.name)} /></div>
                            <div className="text-tertiary text-sm flex items-center">{subnodeNameMap.get(subnode?.type)} in <div className="ml-1 mr-0.5"><NodeIcon type={parentNode.type} databaseTechnology={parentNode.databaseTechnology} iconSize={12} /></div> {subnode?.parentName}</div>
                        </div>
                    </div>
                )
            }
            <div className="mt-3 text-text-primary text-sm"><ExpandableText text={description} maxChars={290} withMarkdown/></div>
            {
                subnode && (
                    <>
                        <ButtonsComponent subnode={subnode} parentNode={parentNode} showCodeModal={showCodeModal} setShowCodeModal={setShowCodeModal} />
                        <NodeUsage node={subnode} />
                    </>
                )
            }
            <NodeInfo
                tags={tags}
                meta={meta}
                info={subnode?.typeSpecificInfo}
            />
            {
                subnode?.hasProposals && parentNode.userAllowedToPromote && <ShiftToDbtBanner onClick={onShiftToDbtClick} />
            }
            {
                getPatQuery.data && (
                    <CreateChangeSidepane
                        isOpen={showProposalSidepane}
                        onClose={() => setShowProposalSidepane(false)}
                        pat={getPatQuery.data}
                    />
                )
            }
        </div>
    );
};

const generateSubnodeUtl = (nodeId: string, subnodeType: SubnodeType, subnodeName: string) => {
    const backendSubnodeType = mapLocalSubnodeTypeToBackendSubnodeType.get(subnodeType);
    return [nodeId, backendSubnodeType, subnodeName].join('.');
};
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import FilterPanel from './DiscoveryFilterPanel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { discoverFilterList, subresourceDiscoverFilters } from './DiscoverFilterList';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useGetCustomPropertiesQuery, useGetFacetsQuery } from 'src/services/nodes/nodes';
import { DiscoverFiltersMenu } from './DiscoverFiltersMenu';
import { useKeyPress } from 'react-use';
import { isTypingInInput } from 'src/infrastructure/domUtilies';
import { isSidepaneOpen } from 'src/components/Sidepane/isSidepaneOpen';
import { useTableView } from 'src/components/Table/useTableView';
import { BackendNodeType } from 'src/services/nodes/types';
import { mapBackendNodeTypeToLocalNodeType } from 'src/services/nodes/transformers';
import { Filter } from './Types';
import { ActiveFilters } from './ActiveFilters';
import { getCustomFilters } from 'src/features/models/discover/toolbar/filters/customFilters';


type DiscoverFiltersBarProps = {
  setFilterValues: (values: string[]) => void;
  tableName: string;
  showSubResources: boolean;
};

export const DiscoverFiltersBar = ({ setFilterValues, tableName, showSubResources }: DiscoverFiltersBarProps) => {
  const [filterToShow, setFilterToShow] = useState<Filter | null>(null);
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const getCustomPropertiesQuery = useGetCustomPropertiesQuery({ accountId });
  const getFacets = useGetFacetsQuery({ accountId });
  const filterOptions = useMemo(() => getFacets.data || {}, [getFacets.data]);
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const fPressed = useKeyPress('f');
  const [searchParams] = useSearchParams();
  const tableView = useTableView({ tableName });
  const savedTableLoaded = useRef(false);
  const filters = useMemo(() => {
    const availableBackendNodeTypes = filterOptions.type as BackendNodeType[] || [];
    const availableNodeTypes = availableBackendNodeTypes.map(t => mapBackendNodeTypeToLocalNodeType.get(t));
    const filtersList = showSubResources ? [...discoverFilterList, ...subresourceDiscoverFilters] : discoverFilterList;
    const filtersRelevantToNodeTypes = filtersList.filter(f => !f.nodeTypes || f.nodeTypes.some(t => availableNodeTypes.includes(t)));
    const filtersWithoutValues = [...filtersRelevantToNodeTypes];
    const customFilters = getCustomFilters(getCustomPropertiesQuery.data || []);
    filtersWithoutValues.splice(2, 0, ...customFilters);
    const filtersWithValues = filtersWithoutValues.map((f) => {
      const value = searchParams.get(f.name) || null;
      return { ...f, value };
    });
    return filtersWithValues;
  }, [getCustomPropertiesQuery.data, filterOptions, searchParams, showSubResources]);

  useEffect(() => {
    savedTableLoaded.current = false;
  }, []);

  useEffect(() => {
    const values = filters.filter((f) => !f.isDisabled && !!f.value).map((f) => f.getEql(f.value));
    setFilterValues(values);
  }, [filters, setFilterValues]);

  //on "f" pressed
  useEffect(() => {
    if (fPressed[0] && !filterToShow && !isTypingInInput() && !isSidepaneOpen()) {
      setShowFiltersMenu(true);
    }
  }, [fPressed, filterToShow]);

  const removeFilter = (name: string) => {
    const newFilters = filters.map((f) => {
      if (f.name === name) {
        return { ...f, value: null };
      }
      return f;
    });
    updateFilters(newFilters);
  };

  const updateFilters = useCallback((newFilters: Filter[], save: boolean = true) => {
    const newUrl = getNewUrlWithFilters(newFilters);
    const currentUrl = `${window.location.pathname}${window.location.search}`;
    if (newUrl !== currentUrl) {
      navigate(newUrl, { replace: true });
      if (save) {
        tableView.saveTableView({ ...tableView.tableView, filters: newFilters.map(f => ({ key: f.name, value: f.value })) });
      }
    }
  }, [navigate, tableView]);

  return (
    <div className="flex items-center gap-4" data-test-id="discover-filter-bar">
      <div className="flex items-center gap-2">
        <div className="text-text-primary">Filter by</div>
        <div className="flex gap-2 flex-wrap">
          <ActiveFilters filters={filters} removeFilter={removeFilter} setFilterToShow={setFilterToShow} />
          <div className="relative">
            {
              showFiltersMenu ? (
                <DiscoverFiltersMenu filters={filters} setFilterToShow={setFilterToShow} onClose={() => setShowFiltersMenu(false)} />
              ) : (
                <div id="open-dropdown-menu-button" className="cursor-pointer rounded-lg bg-white p-1.5 text-text-primary hover:bg-slate-50 border-slate-200 border" onClick={() => setShowFiltersMenu(true)}>
                  <PlusIcon width="16" height="16" />
                </div>
              )
            }
            <FilterPanel
              filters={filters}
              updateFilters={updateFilters}
              filterToShow={filterToShow}
              setFilterToShow={setFilterToShow}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getNewUrlWithFilters = (filters: Filter[]) => {
  const existingParams = new URLSearchParams(window.location.search);
  const newParams = new URLSearchParams();
  for (const filter of filters) {
    if (!filter.isDisabled && !!filter.value) {
      newParams.set(filter.name, filter.value || '');
    }
  }
  for (const [key, value] of existingParams.entries()) {
    if (!filters.some((f) => f.name === key)) {
      newParams.set(key, value);
    }
  }
  const newUrl = `${window.location.pathname}?${newParams.toString()}`;
  return newUrl;
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/layout/Layout';
import { ISuperficialNode, ISuperficialSubnode } from './INode';
import { delay } from '../../../utils/timeUtils';
import { DiscoverView } from './types';
import { DiscoverToolbar } from './toolbar/DiscoverToolbar';
import { DiscoverTableView } from './view/table/DiscoverTableView';
import { NodeSidepaneLoader } from './sidepane/NodeSidepane/NodeSidepaneLoader';
import { DiscoverDAGView } from './view/DAG/DiscoverDAGView';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { notify } from '../../../components/Toaster';
import { nodesApi } from '../../../services/nodes/nodes';
import { SubNodeSidepaneLoader } from './sidepane/SubnodeSidepane/SubnodeSidepaneLoader';
import { ExpandedNode } from './view/DAG/types';
import { events, trackEvent } from '../../../infrastructure/analytics';
import { selectShowSubResourcesInDataModelTable } from 'src/infrastructure/state/slices/showSubResouresInDataModelTableSlice';
import { store } from 'src/infrastructure/state/store';

export const Discover = () => {
  const [selectedNode, setSelectedNode] = useState<ISuperficialNode | null>(null);
  const [nodeSidepane, setNodeSidepane] = useState<ISuperficialNode | null>(null);
  const [subnodeSidepane, setSubnodeSidepane] = useState<{ node: ISuperficialNode, subnode: ISuperficialSubnode } | null>(null);
  const [showNodeSidepane, setShowNodeSidepane] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNode[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const view = useMemo(() => searchParams.get('view') as DiscoverView || DiscoverView.Table, [searchParams]);
  const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
  const eql = filterValues.filter(v => !!v).join(' AND ');
  const tableName = 'data-model-table';

  const setView = useCallback((view: DiscoverView) => {
    searchParams.set('view', view);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!searchParams.get('view')) {
      setView(DiscoverView.Table);
    }
  }, [searchParams, setView]);

  //On node sidepane change
  useEffect(() => {
    if (nodeSidepane) {
      delay(100).then(() => setShowNodeSidepane(true));
      setShowNodeSidepane(false);
    }
    else {
      setShowNodeSidepane(false);
    }
  }, [nodeSidepane, setShowNodeSidepane]);

  //On URL params change
  useEffect(() => {
    //Update node / subnode
    const highlightedUtl = searchParams.get('highlightedNode');
    if (highlightedUtl) {
      trackEvent(events.dataModelEnteredNode);
      store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({ accountId, eql: `uri="${highlightedUtl}"`, page: 1, pageSize: 1, withSubResources: true })).then(res => {
        const node = res.data?.items[0];
        if (node) {
          searchParams.set('UTL', node.id);
          setNodeSidepane(node);
        }
        else {
          notify('Resource not found', 'error');
        }
      }).catch(e => {
        console.error(e);
        notify('Failed to fetch resource', 'error');
      }).finally(() => {
        searchParams.delete('highlightedNode');
        setSearchParams(searchParams, { replace: true });
      });
    }
  }, [navigate, searchParams, view, setSearchParams, accountId]);

  return (
    <Layout>
      <DiscoverToolbar
        setSelectedSubNode={(parentNode, subnode) => { setSelectedNode(parentNode); setSubnodeSidepane({ node: parentNode, subnode }); }}
        setSelectedNode={node => { setSelectedNode(node); setNodeSidepane(node); }}
        view={view}
        setView={setView}
        setFilterValues={setFilterValues}
        showSubResources={showSubResources}
        expandedNodes={expandedNodes}
        setExpandedNodes={setExpandedNodes}
        tableName={tableName}
      />
      {
        view === DiscoverView.Graph && (
          <DiscoverDAGView
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            eql={eql}
            setExpandedNodes={setExpandedNodes}
            expandedNodes={expandedNodes}
          />
        )
      }
      {
        view === DiscoverView.Table && (
          <DiscoverTableView
            setSelectedNode={setSelectedNode}
            showSubResources={showSubResources}
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            eql={eql}
            tableName={tableName}
          />
        )
      }
      <NodeSidepaneLoader
        nodeId={nodeSidepane?.id || ''}
        isOpen={showNodeSidepane}
        onClose={() => setNodeSidepane(null)}
      />
      <SubNodeSidepaneLoader
        nodeId={subnodeSidepane?.node?.id || null}
        subnode={subnodeSidepane?.subnode || null}
        isOpen={subnodeSidepane !== null}
        onClose={() => setSubnodeSidepane(null)}
      />
    </Layout>
  );
};

import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode, ISuperficialNode, NodeType } from "../../../INode";
import { SubNodesListProps } from "src/features/models/discover/sidepane/NodeSidepane/types";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { useEffect, useState} from "react";
import { useLazyGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";
import { NodeSidepaneLoader } from "../NodeSidepaneLoader";

type TableauViewSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauViewSidepane = ({ node, setCanCloseModal }: TableauViewSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        SubNodesListComponent={SubNodesListComponent}
    />;
};

export const SubNodesListComponent = ({ parentNode }: SubNodesListProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [getSubnodesQuery, { data: subnodes, isFetching }] = useLazyGetDataModelResourcesQuery();
    const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);
    useEffect(() => {
        const subnodeUris = parentNode.tableauViewInputFields;
        if (subnodeUris?.length) {
            getSubnodesQuery({eql: `(uri in (${subnodeUris.map(n => `'${n}'`).join(',')}))`, page: 1, pageSize: 1000, accountId, withSubResources: true});
        }
    }, [accountId, parentNode, getSubnodesQuery]);
    return (
        <div className="mt-4">
            <div className="mt-2">
                {isFetching && <ComponentLoader />}
                <>
                  {
                    subnodes?.items.length ? 
                      <div className={`w-full whitespace-nowrap rounded px-1 py-1 text-tertiary bg-white`}>
                          Used Fields
                      </div>
                   : null
                  }
                </>
                {subnodes?.items.map((subnode, index) => <SubnodeItem key={index} subnode={subnode} onClick={(id: string) => setSelectedResourceId(id)} />)}
                <NodeSidepaneLoader nodeId={selectedResourceId || ''} isOpen={!!selectedResourceId} onClose={() => setSelectedResourceId(null)} />
            </div>
        </div>
    );
};

const tableauNodeNameMap = new Map<NodeType, string>([
    [NodeType.TableauDimension, "Dimension"],
    [NodeType.TableauMeasure, "Measure"],
]);

const SubnodeItem = ({ subnode, onClick }: { subnode: ISuperficialNode, onClick: (nodeId: string) => void }) => {
    return (
        <div
            className="flex cursor-pointer items-center justify-between gap-1 border-b border-slate-100 p-1 font-light hover:bg-slate-100"
            onClick={() => {onClick(subnode.id);}}>
            <div className="flex items-center gap-1 overflow-hidden">
                <NodeIcon type={subnode.type} />
                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-secondary">
                    {subnode.name}
                </span>
            </div>
            <span className="text-xs text-tertiary">{tableauNodeNameMap.get(subnode.type) || 'Other'}</span>
        </div>
    );
};

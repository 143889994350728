import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode } from "../../../INode";
import { SubNodesListProps } from "src/features/models/discover/sidepane/NodeSidepane/types";
import { SubNodesList } from "src/features/models/discover/sidepane/NodeSidepane/NodeSidepaneSubnodesList";

type TableauCustomQuerySidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauCustomQuerySidepane = ({ node, setCanCloseModal }: TableauCustomQuerySidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        SubNodesListComponent={SubNodesListComponent}
    />;
};

export const SubNodesListComponent = ({ parentNode, setCanCloseModal }: SubNodesListProps) => {
    return <SubNodesList setCanCloseModal={setCanCloseModal} parentNode={parentNode} subnodes={parentNode.subnodes} />;
};

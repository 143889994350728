import { TableauIcon } from '../../../assets/images/icons/DelphiIcons';
import { ChangeEvent, useEffect, useState } from 'react';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import { EllipsisVerticalIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { Alert } from '../../../components/Alert';
import Input from '../../../components/form/Input';
import { useCreateGenericIntegrationMutation, useDeleteGenericIntegrationMutation, useGetGenericIntegrationsQuery } from '../../../services/integrations/integrations';
import { GenericIntegration, TableauIntegrationConfiguration } from '../../../services/integrations/types';
import { CrawlIntegrationModal } from './CrawlIntegrationModal';
import Checkbox from '../../../components/form/Checkbox';

const TableauIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [showCrawlModal, setShowCrawlModal] = useState(false);
  const { data: integrations = [], error } = useGetGenericIntegrationsQuery({ accountId }, { skip: !accountId });
  const tableauIntegrations = integrations.filter((i) => i.integration_type === 'tableau').map((i) => ({ ...i, configuration: i.configuration as TableauIntegrationConfiguration }));

  useEffect(() => {
    if (error) {
      notify(extractErrorMessage(error).message, 'error');
    }
  }, [error]);
  
  const menuOptions = [
    {
      name: 'Edit configuration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    },
    {
      name: 'Start polling',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowCrawlModal(true);
      }
    }
  ];

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4" dataTestId='Tableau-integration'>
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <TableauIcon width="56" height="56" className="mb-2 text-black" color="black" />
      <span className="mt-2 font-medium">Tableau</span>
      {tableauIntegrations.length === 0 ? (
        <Button
          type={ButtonTypes.secondary}
          text="Configure"
          onClick={() => setShowConfigurationModal(true)}
          className="-mb-2 mt-1 h-6"
          dataTestId='configure-Tableau-integration-button'
        />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {tableauIntegrations.length} active integration{tableauIntegrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={tableauIntegrations}
      />
      <CrawlIntegrationModal
        isOpen={showCrawlModal}
        onClose={() => setShowCrawlModal(false)}
        integrations={tableauIntegrations}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: (GenericIntegration & { configuration: TableauIntegrationConfiguration })[];
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations
}: IntegrationConfigurationModalProps) => {
  const [unlinkIntegration, { originalArgs }] = useDeleteGenericIntegrationMutation();
  const [addingInstance, setAddingInstance] = useState(false);
  const [createIntegration, { isLoading }] = useCreateGenericIntegrationMutation();
  const [configuration, setConfiguration] = useState<TableauIntegrationConfiguration>({ ...emptyConfiguration });
  const [integrationName, setIntegrationName] = useState('');
  const [error, setError] = useState<string>('');

  const onCreateClick = async () => {
    if (!configuration.connect_uri) {
      setError('Host is required');
    }
    else if (!configuration.site) {
      setError('Site is required');
    }
    else if (!configuration.token_name) {
      setError('Token name is required');
    }
    else if (!configuration.token_value) {
      setError('Token value is required');
    }
    else {
      try {
        await createIntegration({ accountId, integrationName, configuration, integrationType: 'tableau' }).unwrap();
        notify('Tableau integration created successfully', 'success');
        setAddingInstance(false);
      } catch (e) {
        setError(`Error creating integration: ${extractErrorMessage(e).message}`);
      }
    }
  };

  useEffect(() => {
    setError('');
  }, [isOpen, configuration]);

  useEffect(() => {
    if (isOpen) {
      setConfiguration({ ...emptyConfiguration });
      setIntegrationName('');
      setError('');
      if (integrations.length === 0) {
        setAddingInstance(true);
      }
    }
  }, [isOpen, integrations.length]);

  const onUnlinkClick = async (integrationId: number) => {
    try {
      await unlinkIntegration({ accountId, integrationId }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      notify(`Error unlinking integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configure Tableau Integration"
      maxWidth="max-w-lg"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-32' }]}
    >
      <div className="rounded border border-slate-200 bg-slate-50">
        {integrations.map((integration) => (
          <div key={integration.name} className="flex items-center justify-between border-b border-slate-200 px-4 py-3">
            <div className="flex items-center">
              <TableauIcon width="20" height="20" className="mr-2" />
              <span className="text-sm" data-test-id="Tableau-integration-name">{integration.name}</span>
            </div>
            {originalArgs?.integrationId === integration.id ? (
              <span className="flex items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Loading...
              </span>
            ) : (
              <span
                onClick={() => onUnlinkClick(integration.id)}
                className="flex cursor-pointer items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Remove
              </span>
            )}
          </div>
        ))}
        <div className="px-4 py-3">
          {!addingInstance && (
            <div
              className="flex items-center text-slate-600 hover:text-slate-800"
              onClick={() => setAddingInstance(!addingInstance)}>
              <PlusCircleIcon width="16" height="16" className="mr-1 cursor-pointer" />
              <span className="cursor-pointer text-sm">Add instance</span>
            </div>
          )}
          <div
            className={`transition-max-height overflow-auto duration-200 ${addingInstance ? 'max-h-76' : 'max-h-0'}`}>
            <InputField label="Integration name">
              <Input
                placeholder="Production"
                value={integrationName}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setIntegrationName(e.target.value)}
              />
            </InputField>
            <InputField label="Connection URL">
              <Input
                placeholder="https://us-west-2b.online.tableau.com/"
                value={configuration.connect_uri}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, connect_uri: e.target.value })}
              />
            </InputField>
            <InputField label="Site">
              <Input
                placeholder="Sales"
                value={configuration.site}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, site: e.target.value })}
              />
            </InputField>
            <InputField label="Token name">
              <Input
                placeholder="Prod"
                value={configuration.token_name}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, token_name: e.target.value })}
              />
            </InputField>
            <InputField label="Token value">
              <Input
                placeholder="123abc"
                value={configuration.token_value}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration, token_value: e.target.value })}
                type='password'
              />
            </InputField>
            <div className="flex gap-2">
              <Checkbox
                value={!configuration.ssl_verify}
                setValue={(value) => setConfiguration({ ...configuration, ssl_verify: !value })}
              />
              Skip SSL certificate verification
            </div>
            <div>{error && <Alert title="Error:" text={error} type="error" className="mt-2" />}</div>
            <div className="mt-4 flex h-8 flex-row-reverse">
              <Button
                type={ButtonTypes.secondary}
                text="Save"
                onClick={onCreateClick}
                isLoading={isLoading}
                className="w-24"
                dataTestId='Tableau-integration-save-button'
                enterToSubmit={true}
              />
              {integrations.length > 0 && (
                <Button
                  type={ButtonTypes.secondary}
                  text="Cancel"
                  onClick={() => setAddingInstance(false)}
                  className="w-24 mr-2"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const InputField = ({ children, label }: { children: React.ReactNode; label: string }) => {
  return (
    <div className="mb-4 flex items-center justify-between gap-2 text-text-primary">
      <div className="w-28">{label}</div>
      <div className="flex-1">{children}</div>
    </div>
  );
};

const emptyConfiguration: TableauIntegrationConfiguration = {
  connect_uri: '',
  site: '',
  token_name: '',
  token_value: '',
  ssl_verify: true
};

export default TableauIntegration;

import { NodeSidepane } from "./NodeSidepane";
import { IExpandedNode } from "../../INode";

type TableSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableSidepane = ({ node, setCanCloseModal }: TableSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        ButtonsComponent={ButtonsComponent}
    />;
};

export const ButtonsComponent = () => null;